import { SiteMapProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { detail, heading } from "@schemas/presets";

const schema: Schema.Template<SiteMapProps> = {
	schemaType: "template",
	displayName: "Site Map Template",
	component: "SiteMap",
	type: { label: "Static", value: "static", special: "sitemap" },
	singleInstance: true,

	content: [
		{
			type: "ComponentArray",
			title: "Hero",
			maxItems: 1,
			whiteList: [""],
			key: "heroSection",
			mandatory: true,
			contentType: "modules",
		},
		{ ...heading, mandatory: false },
		{ ...detail },
		{
			type: "ComponentArray",
			title: "Navigation",
			whiteList: [""],
			key: "navigation",
			mandatory: true,
			contentType: "modules",
		},
	],

	default: {
		type: "template",
		templateType: "SiteMap",
		heroSection: {
			component: "Section",
			name: "Hero Section",
			modules: [],
		},
		navigation: {
			component: "Section",
			name: "Navigation",
			modules: [],
		},
		title: { content: "Lorem ipsum", tag: "h1" },
		detail:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Elementum urna facilisis porta consectetur adipiscing.",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/SiteMap/thumbnail@1x.png",
		"2x": "/thumbnails/templates/SiteMap/thumbnail@2x.png",
	},
};

export default schema;
