import BasicTemplate from "./BasicTemplate";
import Error404 from "./Error404";
import LandingProgram from "./LandingProgram";
import LegalTemplate from "./LegalTemplate";
import NewsDetail from "./NewsDetail";
import NewsList from "./NewsList";
import ProfessorDetail from "./ProfessorDetail";
import ProfessorList from "./ProfessorList";
import ProgramDetail from "./ProgramDetail";
import ProgramList from "./ProgramList";
import SiteMap from "./SiteMap";

export default {
	BasicTemplate,
	Error404,
	LandingProgram,
	LegalTemplate,
	NewsDetail,
	NewsList,
	ProfessorDetail,
	ProfessorList,
	ProgramDetail,
	ProgramList,
	SiteMap,
};
