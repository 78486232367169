import { Schema } from "@griddo/core";

export const PROFESSORS: Schema.PageContentType = {
	dataPacks: ["PROFESSORS_PACK"],
	title: "Professor (Auto)",
	local: true,
	fromPage: true,
	translate: true,
	taxonomy: false,
	editable: false,
	schema: {
		templates: ["ProfessorDetail"],
		fields: [
			{
				type: "TextField",
				key: "title",
				title: "Title",
				from: "title",
			},

			{
				type: "TextField",
				title: "Name",
				from: "name",
				key: "name",
			},

			{
				type: "TextField",
				title: "Surname",
				from: "surname",
				key: "surname",
			},

			{
				type: "TextField",
				title: "PhD",
				from: "phd",
				key: "phd",
			},

			{
				type: "TextField",
				title: "Office number",
				from: "officeNumber",
				key: "officeNumber",
			},

			{
				type: "ImageField",
				title: "Image",
				key: "image",
				from: "image",
			},

			{
				type: "TextField",
				title: "Email",
				key: "email",
				from: "email",
			},

			{
				type: "TextField",
				title: "CV",
				key: "cv",
				from: "cv",
			},

			//			{
			//				type: "ComponentContainer",
			//				from: "mainSection",
			//				key: "mainSection",
			//				title: "Main Section",
			//				whiteList: ["SocialShare", "TabsModules"],
			//			},

			{
				type: "TextField",
				title: "External ID",
				from: "externalID",
				key: "externalID",
			},

			{
				title: "Departments",
				type: "MultiCheckSelect",
				key: "departments",
				from: "departments",
				source: "DEPARTMENTS",
				placeholder: "Departments",
			},

			{
				title: "Professor Type",
				type: "MultiCheckSelect",
				key: "professorType",
				from: "professorType",
				source: "PROFESSOR_TYPE",
				placeholder: "Professor type",
			},
		],
	},
};
