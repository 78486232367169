import { Schema } from "@griddo/core";

const schema: Schema.ModuleCategories = [
	{
		label: "Articles & Events",
		value: "articlesAndEvents",
	},
	{
		label: "Collection",
		value: "collection",
	},
	{
		label: "Content",
		value: "content",
	},
	{
		label: "Distributors",
		value: "distributors",
		featured: true,
	},
	{
		label: "Featured",
		value: "featured",
	},
	{
		label: "Hero",
		value: "hero",
	},
	{
		label: "Interactive",
		value: "interactive",
	},
	{
		label: "People",
		value: "people",
	},
	{
		label: "Social",
		value: "social",
	},
	{
		label: "Studies",
		value: "studies",
	},
	{
		label: "Spacers",
		value: "spacers",
		featured: true,
	},
];

export default schema;
