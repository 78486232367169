import { PeopleDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchorID,
	defaultLink,
	detail,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.ContentTypeModule<PeopleDistributorProps> = {
	schemaType: "module",
	component: "PeopleDistributor",
	category: "people",
	displayName: "People Distributor",
	dataPacks: ["PROFESSORS_PACK"],
	sectionList: {
		BasicTemplate: ["mainSection"],
		ProfessorDetail: ["complementarySection"],
	},

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },
				{ ...detail },
				{
					type: "ReferenceField",
					title: "Professors",
					key: "data",
					sources: [{ structuredData: "PROFESSORS" }],
					selectionType: ["auto", "manual"],
				},
				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Link"],
					hideable: true,
				},
			],
		},
		{
			title: "config",
			fields: [{ ...anchorID }, { ...themeSelector }, { ...verticalSpacing }],
		},
	],

	default: {
		component: "PeopleDistributor",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		detail: "Lorem ipsum paragraph",
		hasDistributorData: true,
		data: {
			mode: "auto",
			order: "alpha",
			quantity: 2,
			sources: [
				{
					structuredData: "PROFESSORS",
				},
			],
			/* ---------- */
			/* opcionales */
			/* ---------- */
			// order: 'alpha-ASC',
			// filter: ['string'],
			// fullRelations: true,
			// quantity: 2
		},
		link: {
			...defaultLink,
			text: "Ver más",
			variant: "tertiary",
		},
		anchorID: null,
		verticalSpacing: "medium",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/PeopleDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/PeopleDistributor/thumbnail@2x.png",
	},
};

export default schema;
